export default {
    "en": {
        "main.sitename": "iWannaboat.com",
        "main.back.to_webiste": "Back to the website",
        "main.quit.your.account": "Quit your account",
        "main.currency": "Currency",
        "main.yes": "Yes",
        "main.no": "No",
        "main.what": "Quoi",
        "main.close": "Fermer",
        "main.title": "Title",
        "main.send": "Send",
        "main.delete": "Delete",
        "main.see": "See",
        "main.update": "Change",
        "main.name": "Name",
        "main.phone": "Phone",
        "main.back": "Back",
        "main.back.to.homepage": "Back to homepage",
        "main.for.sale": "fore sale",
        "main.sails": "Sails",
        "main.for": "for",
        "main.no.ads.message": "Unfortunately, there is no ad for your criterias captain, can you search something else ?",
        "main.no.ads.button.label": "Or publish your own",
        "main.cta.ad.contact": "Contact me !",
        "main.used.boats": 'Used boats',
        "main.used.boats": 'used',
        "main.harbour.office": "Harbour office",
        "main.flag.france": "🇫🇷",
        "main.flag.england": "🇬🇧",
        "main.favorites": "Favorites",
        "main.guide.of": "Guide of {name}",
        "main.used": "used",
        "main.famous.sailor.email": "ben@ainslie.com",
        "main.sell": "For sale",
        "main.rent": "For rent",
        "main.search": "Looking for",
        "main.validate": "Validate",
        "main.save": "Save",
        "main.cancel": "Cancel",




        "main.brand.moto": "⚓ iWannaboat.com, the nautical classified ads with politeness and without scams",
        "main.brand.moto.2": "Your boat deserves the best small ad service",

        "about.hi.captain": "Hi Captain !",
        "about.what.do.you.want": "What would you like to do",
        "about.publish.an.ad": "Publish an ad",
        "about.access.to.your.account": "Access to your account",
        "about.get.more.infos": "Get more infos about iWannaboat",
        "about.contact.us": "Contact",
        "about.go.to.pro.list": "Access to professionnals list",
        "about.read.our.blog": "Read our blog",
        "about.page.contact.notice": "If you want to contact us directly, send a message at : {email}, we always reply !",
        "about.alloffame.title": "iWannaboat users All of Fame",
        "about.alloffame.notice": "Here are the names of those I wish to thank. iWannaboat a little, (even a lot for some) thanks to them.",




        "home.welcome": "Welcome on Wanaboat",
        "home.declarative": "The best website to sell or buy a boat",

        "univers.most.expensive": "The most expensive {name}",
        "univers.cheapest": "The cheapest {name}",

        "nav.nav": "Nav",
        "nav.back": "Go back",
        "nav.publish": "Publish your ad",
        "nav.userAccount": "Your account",
        "nav.about.item": "About iWannaboat.com",

        "breadcrumb.used.boats": "Used boats",
        "pagination.current.page": "Page {currentPage} on {numPages}",


        "header.h1": "buy/sell second hands, ads and prices",
        "header.h2.barely-docked": "Barely docked, here is ",
        "header.h2.last-univers-ads": "the last {name} ads at the best price",
        "header.h2.for-you": "for you on iWannaboat",

        "models.list.title": "All {universName} models for sale",
        "models.list.title.generic": "All second hand models for sale",
        "brand.model.made.by": "Models built by {name}",

        "brands.specialized.boats.builder": "{universName} boats builders",

        "cta.publish.with.univers": "Publish a free {name} ad →",
        "cta.see.all.ads": "Go to all ads ↓",
        "cta.see.all.brands": "Sell all brands",
        "cta.see.all.models": "See all",
        "cta.see.less.models": "See less",
        "cta.publish.in.three.minutes": "Publish your ad in 3 min",

        "ad.price": "Price",
        "ad.area": "Area",
        "ad.builder": "Builder",
        "ad.sail.maker": "Maker",
        "ad.brand": "Brand",
        "ad.country": "Country",
        "ad.year": "Year",
        "ad.reply.average.delay": "answers on average in",
        "ad.message.send.sucess.notice": "Your message has been successfully relayed to the VHF Captain. Keep an eye your email for the answer.",

        "filters.filters": "Filters",
        "filters.all": "All",
        "filters.type": "Kind",
        "filters.sorting": "Sorting",
        "filters.categories": "Categories",
        "filters.most.recent": "The most recent",
        "filters.cheapest": "The cheapest",
        "filters.most.expensive": "The most expensive",
        "filters.all.together": "All together",
        "filters.full.boats.only": "Boats only",
        "filters.sails.only": "Sails only",
        "filters.parts.only": "Parts only",
        "filters.trolleys.only": "Trolleys only",
        "filters.countries": "Countries",
        "filters.type.for.sale": "To sell",
        "filters.type.for.rent": "To rent",
        "filters.type.looking.for": "Look for",


        "ad.pictures": "{count} picture",
        "ad.pictures.plural": "{count} pictures",
        "ad.gallery.close.the.diaporama": "Close the diaporama",
        "ad.maker": "Maker",
        "ad.department": "Region",
        "ad.direct.link": "Direct link",
        "ad.share": "Share",
        "ad.send": "Send",
        "ad.localisation": "Localisation",
        "ad.title.details": "Ad details : ",
        "ad.obsolete.answer": "This ad is no longer current? The property is sold and you know it? Let me know, i'll investigate. vhf@iwannaboat.com Thank you! Objective: 100% valid ads, help me!",
        "ad.copy.button": "Copy",
        "ad.copy.button.ok": "It's ok !",
        "ad.related.ads.list.title": "You can also take a look to this ones",
        "ad.sold.by": "Sold by",

        "seller.contact.form.name": "Name / pseudo",
        "seller.contact.form.phone": "Phone",
        "seller.contact.form.send.message": "Send your message",
        "seller.contact.form.fill.first": "Fill the form first",
        "seller.contact.form.back.to.ad": "Back to ad",
        "seller.contact.form.first.message.disclaimer": "This is your first message here Captain, first of all welcome aboard. I will create an account for you and at the same time as your message will be sent to the seller, I will send you email access. So I absolutely need a valid address 👍",
        "seller.contact.form.privacy.disclaimer": "At the iWannaboat harbour master's office, we protect salesmen from unwanted solicitation and small ad scammers. So, you have to show your credentials, it's for a good cause!",
        "seller.contact.form.filling.disclaimer": "All fields are mandatory. Don't worry, everything is secure, your details will be a better-kept secret than the location of Francis Drake's treasure. We'll pass them on to the seller to get back to you.",
        "seller.contact.form.language.fr.notice": "This seller mainly uses the French language",
        "seller.contact.form.language.en.notice": "This seller mainly uses the English language",
        "seller.contact.form.notice.contact.as": "You will contact the seller as",

        "countries.all": "all",
        "countries.great.britain": "Great britain",
        "countries.france": "France",
        "countries.germany": "Germany",
        "countries.belgium": "Belgium",
        "countries.swiss": "Swiss",
        "countries.italy": "Italy",
        "countries.spain": "Spain",
        "countries.switzerland": "Switzerland",
        "countries.netherlands": "Netherlands",
        "countries.usa": "USA",
        "countries.australia": "Australia",
        "countries.new.zealand": "New Zealand",
        "countries.portugal": "Portugal",


        "model.subscribe.form.title": "Receive {modelName} ads by email",
        "model.subscribe.form.validate": "Validate",
        "model.subscribe.form.message": "So you don't miss the good announcement",
        "model.subscribe.form.confirmation": "It is ok captain ! The next announcements {modelName} will arrive in your mailbox the night after publication.",
        "model.subscribe.form.interested.by": "Interested by the {modelName}",
        "model.subscribe.button.label": "Subsribe to alerts",

        "model.price.table.title": "Used {modelName} price table",
        "model.stats.title": "Some figures of the {modelName} {universName}",
        "model.stats.other.brands": "Various brands",
        "model.charts.price.year.title": "Average price of {modelName} boat according production date",
        "model.charts.price.brand.title": "Average price of {modelName} boat according builder",
        "model.presentation.title": "{modelName} presentation",
        "brand.presentation.title": "{modelName}'s presentation",
        "model.see.europe.map.of.model": "Discover {modelName} Europe map",

        "model.table.ad.name": "Advert title",


        "model.stats.average.price": "Average price",
        "model.stats.median.price": "Median price",
        "model.stats.sold.boats": "Boats sold this year",
        "model.stats.min.price": "Min price",
        "model.stats.max.price": "Max price",
        "model.stats.available.boats": "Available boats",
        "model.stats.launch.year": "Launch year",
        "model.stats.sail.area": "Sail area",
        "model.stats.weight": "Weight",
        "model.stats.designer": "Boat designer",
        "model.stats.crew": "Crew",
        "model.stats.notice": "These statistics take into account all the {modelName} ads that have been published, some of which are no longer online today",
        "model.versions.title": "The model {modelName} has {versionsCount} differents versions",

        "model.export.tab.ads": "Ads directory",
        "model.export.tab.map": "Ads map",
        "model.export.tab.market": "Market stats of {modelName}",

        "model.no.ad.notice.title": "There is no {name} for sale",
        "model.no.ad.notice": "Subscribe to this series (just above) so you don't miss the next opportunity.",

        "brand.presentation.title": "{modelName} presentation",
        "brand.main.title": "Main makers of category",


        "account.validation.waiting.message": "Validation of your account in progress.",
        "account.validation.its.ok": "It's done, Captain!",
        "account.validation.notice.1": "Your account is validated and your ad will be online in a few moments. This step is to make sure that you receive our emails because when a buyer contacts you, you will be alerted by email.",
        "account.validation.notice.2": "To be perfectly sure to receive our emails, you can even add «vhf@iwannaboat.com» to your address book.",
        "account.validation.back.to.homepage": "Go back to homepage",

        "account.login.notice": "Hello Captain, in order to identify you, we are going to send you a message by email. This message contains a link that you just have to click on. Simple, isn't it?",
        "account.login.your.email": "Your email",
        "account.login.send.link": "Send identification link now",
        "account.login.message.sent.notice": "The identification link has just been sent to you by email Captain, it must already be in your inbox. Feel free to bookmark it.",
        "account.login.verification": "Checking of your",

        "account.nav.welcome": "Your account",
        "account.nav.discussions": "Discussions",
        "account.nav.classifieds": "Your ads",
        "account.nav.alerts": "Your alerts",
        "account.nav.settings": "Settings",
        "account.nav.setttings.your.contact": "Your contacts",
        "account.nav.setttings.email.update.notice": "To update your email address, please send a request to vhf@iwannaboat.com",

        "account.welcome.message": "Hello, welcome to your quarters. Here below, you will find all you need to manage your account and your ads. Make yourself at home Captain.",
        "account.message.you.have.not.been.contacted": "You do not currently have a conversation in progress.",
        "account.message.from": "From",
        "account.message.to": "To",
        "account.message.new": "New",
        "account.message.for": "for",
        "account.message.velocity.notice": "Captain, you should know, the time it takes you to reply to your messages is measured and used in the classification of the ads. It rewards those who interact quickly with buyers, it's for a good cause ;)",
        "account.message.is.read": "Read",

        "account.alerts.title": "Here is the list of your models's subscriptions",
        "account.alerts.notice": "When a new ad is published you will receive an email the next night.",
        "account.alerts.no.alert.notice": "You don't have an alert yet. To create one, on the page of a model put your email in the box «receive alerts».",

        "form.international.number": "International number",
        "form.international.placeholder": "Phone number without prefix",
        "before.you.start": "Befor you start",

        "account.settings.log.out": "Log out",
        "account.settings.log.out.notice": "At the Wanaboat.fr harbour master's office, you are always welcome. Thus, the connection is permanent as long as you do not change your computer or telephone. So, if you need to get away from it all for a while, it's here :",
        "account.settings.newsletter.notice": "Would you like to receive our newsletter (from 2 to 6 per year depending on the capacity of the harbour master's office sailors to write some of them)",
        "account.settings.newsletter.receive": "Signup to the newsletter",
        "account.settings.final.deletion": "Final deletion",
        "account.settings.final.deletion.notice": "If you wish to permanently delete your account, write to the deckhand on watch at vhf@iwannaboat.com",
        "account.settings.preferred.language": "Your prefered language",
        "account.settings.preferred.language.notice": "You prefer that we talk to you",

        "account.message.phone": "Phone",
        "account.do.you.want.to.share.contact": "Do you want to exchange your phone + email details with the buyer?",
        "account.archived.discussion.notice": "The advert for this discussion has been removed and is read-only.",
        "account.see.archived.discussions": "See archives",
        "account.archived.discussion": "Archived discussions",
        "account.ad.being.discussed": "The ad being discussed",
        "account.buyer.contact.card.seller": "Seller's contact",
        "account.buyer.contact.card.buyer": "Buyer's contact",
        "account.buyer.contact.notice.1": "If you trust this contact person, you can ",
        "account.buyer.contact.button": "exchange contact details",
        "account.buyer.contact.notice.2": "Be careful, your exchanges are protected with an anti-scamming system as long as you exchange here. As soon as you exchange by email or phone, I don't protect you from anything. Be careful.",

        "account.ad.no.ad.online.notice": "You currently have no ads published on iWannaboat",

        "login.form.name.and.contact": "Nom ou raison social",

        "form.international.number": "International number",
        "form.international.placeholder": "Number without phone code",

        "form.lets.go.captain": "Let's go my Captain",
        "form.step1.title": "First of all, what do you have to sell",

        "form.step1.sell": "Sell",
        "form.step1.rent": "Look for",
        "form.step1.search": "Rent",

        "form.step1.complete.boat": "A complete boat",
        "form.step1.complete.sails": "Some sails",
        "form.step1.complete.parts": "Some equipement and parts",
        "form.step1.complete.trolley": "A trolley",

        "form.step2.notice1": "We will take the input for",
        "form.step2.notice2": "It will take you about 3 minutes. You must have a short description",
        "form.step2.notice3": "of what you have to sell plus some pictures",

        "form.step2.your.boat": "your boat",
        "form.step2.your.sails": "your sails",
        "form.step2.your.parts": "your parts",
        "form.step2.your.trolley": "your trolley",
        "form.step2.its.a": "It's a",
        "form.step2.of": "For",
        "form.step2.for.a": "For a",

        "form.step2.catamaran": "Catamaran",
        "form.step2.sailing.dinghy": "Sailing dinghy",
        "form.step2.dayboat": "Dayboat",
        "form.step2.offshore.racing.boat": "Offshore racing yacht",
        "form.step2.cruising.sailboat": "Cruising sailboat",

        "form.step3.which.model": "Which series / model?",
        "form.step3.which.for.which.model": "For which series / model?",
        "form.step3.notice": "If you can't find it, choose «Various» and we'll create the template afterwards.",
        "form.step3.which.version": "Of those version ?",

        "form.step35.build.when": "Built which year",
        "form.step35.i.dont.knwow": "I don't know",
        "form.step35.it.comes.back.to.me": "It comes back to me",

        "form.step35.built.by.which.shipyard": "Made by which shipyard?",
        "form.step35.from.which.sailmaker": "From which sailmaker ?",
        "form.step35.which.maker": "Which is the maker ?",
        "form.step35.brand.notice": "If it doesn't exist, choose «Other brand».",
        "form.step.35.other.brand": "Other brand",

        "form.step4.where.is": "Where is",
        "form.step4.the.boat": "the boat",
        "form.step4.the.sails": "the sail(s)",
        "form.step4.the.parts": "the part(s)",
        "form.step4.the.trolley": "the trolley",
        "form.step4.is.it.here": "Is it here ?",
        "form.step4.notice": "Type the city, and possibly the department or the country if it is outside Europe.",
        "form.step4.confirm.this.location": "Confirm this location",
        "form.step.4.city.department.country": "City, Region, Country",

        "form.step5.title.of.your.ad": "Title of your ad",
        "form.step5.notice": "Here is our proposal, if you wish to modify it, it is possible.",
        "form.step5.validate.this.title": "Confirm this title",
        "form.step5.in": "in",
        "form.step5.parts.of": "Parts of",

        "form.step6.describe.precisely.what.you.sell": "Describe precisely what you are selling",
        "form.step6.validate.this.description": "Confirm this description",
        "form.step6.wysiwyg.boat.placeholder": "Describe the boat you are selling down to the last detail",
        "form.step6.wysiwyg.other.placeholder": "Describe the material you sell in every detail",

        "form.step7.pictures": "Pictures",
        "form.step7.of.the.boat": "of the boat",
        "form.step7.of.the.sails": "of the sail(s)",
        "form.step7.of.the.parts": "of the part(s)",
        "form.step7.of.the.trolley": "of the trolley",
        "form.step7.select.the.pictures": "Select pictures",
        "form.step7.as.many.pictures": "As many pictures as you want, when you love, you don't count ;)",
        "form.step7.continue.without.pictures": "Continue without pictures",
        "form.step7.notice": "Click on «Choose» to select the cover photo of the ad.",
        "form.step7.primary": "Primary",
        "form.step7.choose": "Choose",
        "form.step7.confirm.picture": "Confirm this picture",
        "form.step7.confirm.pictures": "Confirm this {count} pictures",

        "form.step8.price.label": "And finally the price",
        "form.step8.notice": "The price is mandatory, you can mention «to be discussed» in your ad, but the asking price is necessary.",
        "form.step8.notice.2": "If your price is not in Euro, we will convert it accordingly",

        "form.step9.is.it.you": "It is you...? (Can you fill in the missing information?)",
        "form.step9.i.valid": "It's ok",
        "form.step9.fill.your.infos.first": "Fill your infos first please",
        "form.step9.yes.its.ok": "Yes it's ok",
        "form.step9.name.and.contact": "Name, company name & contacts",
        "form.step9.phone.and.prefix": "Phone, code + number",
        "form.step9.email.address": "Your email address",
        "form.step9.email.address.confirmation": "Email address confirmation",
        "form.step9.notice": "Your contact details will not be publicly visible. We will send you the contact details of interested parties by email. You can indicate them without fear.",

        "form.step.10.fully.fill.notice": "Fill in your details in their entirety before you can proceed",
        "form.step10.confirm.ad.and.finalize": "Confirm the ad and finalize",

        "form.step11.title": "It's almost finish my Captain",
        "form.step11.what.did.you.think": "What did you think of our form",
        "form.step11.meh": "Meh",
        "form.step11.correct": "Correct",
        "form.step11.its.sucks": "It sucks",
        "form.step11.its.awful": "It's awful",
        "form.step11.notice": "It is very important for us to know how to improve the service to the maximum!",
        "form.step11.publish.your.ad": "Publish your ad",
        "form.step.11.what.could.be.improved": "What could be improved to make it perfect?",
        "form.step.11.placeholder": "Because we really want it to be perfect, to satisfy you 100% next time.",
        "form.step.11.five.stare.rate.comment": "It gives us a great pleasure ! Good luck for selling, Captain!",

        "form.step.11.congratulations.captain": "Congrats Captain",
        "form.step.11.notice.2": "Your ad will be online in a few minutes for a few final checks.",
        "form.step.11.notice.3": "You must already have an email in your inbox with a link on which you must click to activate the ad. It will be online as soon as you have done this last manipulation.",
        "form.step.11.notice.4": "Yippee, it's over. You will receive the buyers' requests for information by email, so keep an eye on your mailbox Captain",
        "form.step.11.notice.5": "I will also send you an email with some tips on how to make your ad visible",
        "form.step.11.go.back.to.homepage": "Back to homepage",

        "form.update.translation.notice": "Your ad was automatically translated when it was created. Here you can make changes to the translation of the title and description to improve its presentation on the international iWannaboat french site",

        "donation.title": "Captain, would you be so kind as to offer me a beer",
        "donation.appreciate": "Captain, you seem to have enjoyed the service, do you want to support it",
        "donation.notice.1": "Wanaboat is not Google or Ebay, it's a passionate project, made to help seamen. It is free for users but there are some costs to run it and I spend a lot of time on it to make the site efficient, pleasant and safe.",
        "donation.notice.2": "Running the machine costs about 100 Dollars per month (server + email system mainly). I pay out of my own pocket... minus your donations. I therefore ask you to help me to share the bill if you wish and according to your financial possibilities.",
        "donation.notice.3": "Captain, you just posted an ad on iWannaboat and enjoyed the experience. First of all, thank you for the 5 ⭐ it feels good and it motivates. I have a somewhat solemn request to make to you",
        "donation.notice.4": "would you like to support the service",


        "donation.will.be.used": "Your donation will be used for :",
        "donation.will.pay.charges": "pay the charges (hosting & email routing)",
        "donation.will.keep.service.without.advert": "Keeping a site free of advertising and personnal data sales",
        "donation.will.upgrade.service": "improve and perpetuate the service... and have a drink sometimes",
        "donation.have.a.drink": "...and have a drink sometimes",
        "donation.of.the.price": "of the value",
        "donation.of.your": "of your",
        "donation.refuse.button.label": "No, next time, just delete my ad deckhand !",
        "donation.price.1.notice": "1 beer for me 🍺",
        "donation.price.2.notice": "1 bière for me and my girl 🍻",
        "donation.price.3.notice": "a round for me and my mates 🍻 🍻",
        "donation.price.4.notice": "A new beer tap for the clubhouse 🍻 🍻 🍻",

        "donation.price.5.notice": "A coffee",
        "donation.price.6.notice": "A beer",
        "donation.price.7.notice": "A beer with fries",
        "donation.price.8.notice": "A beer with fries",

        "donation.custom.notice": "None of these amounts apply to you, choose your own",
        "donation.custom.button.label": "Thanks, that's great",
        "donation.custom.field.placeholder": "To your good heart",

        "donation.thankyou.title": "It gets me all hot at ❤️ Captain!",
        "donation.thankyou.notice": "Your generosity motivates me to continue to do my utmost to offer you an even better iWannaboat !",
        "donation.thankyou.notice.2": "Your donation provides the means for Wanaboat to continue to offer a friendly, efficient and ad-free service, thank you.",
        "donation.thankyou.go.home.button.label": "Back to the homepage under my applause",

        "ad.preview.title1": "Hi Captain",
        "ad.preview.title2": "We've spotted your wonderful {modelName} for sale, and here's what we've got for you:",
        "ad.preview.notice1": "iWannaboat.com is a free dedicated to nautical classified ads. The site is simple and super secure.",
        "ad.preview.notice2": "People are currently looking for a {modelName} like your.",
        "ad.preview.notice3": "A question before you start? Contact me at vhf@iwannaboat.com",
        "ad.preview.notice4": "You just have to fill in 3 details and it will be done in 1 minute",
        "ad.preview.notice5": "Your ad will be online in a few moments. Then I will send you the details by email.",

        "ad.preview.arg1": "We put the ad for you on iWannaboat",
        "ad.preview.arg2": "It is free of charge, there is no particular commitment",
        "ad.preview.arg3": "You just have to click on «yes» and fill in 2 infos",

        "ad.preview.button.yes.label": "Yes, let's go",
        "ad.preview.button.no.label": "No, i'm not interested",
        "ad.preview.button.back.to.homepage": "Back to homepage",
        "ad.preview.great.lets.go": "Great, let's go",

        "ad.preview.its.ok": "It's ok now",

        "favorites.panel.title": "Your favorites ads",
        "user.creation.short.form.label": "Your email",
        "user.creation.short.form.email.notice.1": "Captain, i need your email to save your favorites",
        "user.creation.short.form.email.notice.2": "I don't share it with anyone for sure",

        "delete.wizard.title": "Deleting your ad",
        "delete.wizard.notice.1": "One last important question for the statistics of the site my Captain",
        "delete.wizard.notice.2": "Was iWannaboat useful to you",

    }
}